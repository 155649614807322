<template>
  <IqDropDown
    v-if="navigation.length > 0"
    :title="title"
    :options-list="navigation"
    key-label="title"
    @onCommand="handleDropdownClick"
  />
</template>

<script>
import { GET_TERMINAL_CURRENT_ID_SLUG } from '@/store/actions'
import { mapGetters } from 'vuex'
import { navigationList, navigationListStep } from '@/constants/navigaiton'
import IqDropDown from '@/views/ui/components/dropdown/IqDropDown'
const defaultTitle = 'Выберите раздел'

export default {
  name: 'ExtraMenu',
  components: { IqDropDown },
  props: {
    resetExtraMenuTitle: { type: Number, required: true },
  },
  data() {
    return { title: defaultTitle, navigationListStep, navigationList }
  },
  computed: {
    ...mapGetters({
      terminalSlug: GET_TERMINAL_CURRENT_ID_SLUG,
    }),
    navigation() {
      return this.terminalSlug === 'step'
        ? this.navigationListStep.find(
            item => item.route === this.getParentPageRoute,
          )?.children || []
        : this.navigationList.find(
            item => item.route === this.getParentPageRoute,
          )?.children || []
    },
  },
  watch: {
    resetExtraMenuTitle() {
      this.title = defaultTitle
    },
    navigation: {
      handler(val) {
        const currentItem = val.filter(
          item => this.$route.name === item.name,
        )[0]

        if (this.$route.name.includes('Control-')) {
          currentItem === undefined
            ? this.$router.push({ name: 'Control-terminal' })
            : (this.title = currentItem.title)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleDropdownClick(id) {
      const currentItem = this.navigation.filter(item => item.id === id)[0]

      const { name, dialog } = currentItem

      if (name) {
        this.$router.push({ name })
        this.title = currentItem.title
      }
      if (dialog) {
        this.setDialog({
          name: dialog,
          visible: true,
        })
      }
    },
  },
}
</script>
<style lang="sass">
.extra-menu
  & .icon-extra-menu
    margin-right: 18px
  & .el-dropdown-link
    display: flex
    align-items: center
</style>
