var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.navigation.length > 0
    ? _c("IqDropDown", {
        attrs: {
          title: _vm.title,
          "options-list": _vm.navigation,
          "key-label": "title",
        },
        on: { onCommand: _vm.handleDropdownClick },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }